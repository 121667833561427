import React from 'react';
import Layout from './../components/Layout'
import {graphql} from 'gatsby';
import {makeStyles} from '@material-ui/core';
import {Helmet} from 'react-helmet';
import { GatsbyImage } from 'gatsby-plugin-image/';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import {RedditShareButton, LinkedinShareButton, TwitterShareButton, RedditIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import readingTime from 'reading-time';
import { CodeBlock, nord } from "react-code-blocks";


const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <div className="asset-wrapper">
           <GatsbyImage className="asset" image={node.data.target.gatsbyImageData} alt=""/>
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry = node.data.target;
        if(entry.__typename==="ContentfulCodesnippet"){
          return (<div className="code-wrapper">
                    <CodeBlock
                      
                      text = {entry.code.code}
                      language={entry.language}
                      showLineNumbers={false}
                      theme={nord}
                    />
                  </div>)
        }
       
        }
    },
 };
  




const useStyles = makeStyles({
    article:{
        marginTop:200,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        '@media (max-width:750px)': {
          marginTop:150,
       },
        
    },
    title:{
      textAlign:"center",
      fontSize:40,
      marginBottom:40,
      '@media (max-width:875px)': {
        fontSize:30,
     },
     '@media (max-width:650px)': {
      fontSize:25,
   },
    },
    socialsShare:{
      display:"flex",
      gap:10,
      marginBottom:50,
      
      "& svg":{
        '@media (max-width:875px)': {
          width:30,
          height:30
       },
      },
      
    },
    readingTime:{
      color:"grey",
      fontWeight:500,
      marginBottom:30
    },
    date:{
      textAlign:"center",
      fontSize:16,
      fontWeight:500,
      color:"grey",
    },
    cover:{
      marginTop:50,
      maxHeight:500,
      borderRadius:10,
      marginBottom:60,
    },
    content:{
      width:"85%",
      '@media (max-width:670px)': {
        width:"95%",
     },
      "& a":{
        color:"#68F4A9",
        "&:hover":{
          color:"#68F4A9"
        }
      },
      "& p, li":{
        marginBottom:25,
        fontSize:17,
        lineHeight:"150%",
        '@media (max-width:875px)': {
          fontSize:15,
       },
       
      },
      "& .bold":{
        fontWeight:500,
        color:"#68F4A9",
      },
      "& h1,h2,h3,h4":{
        color:"#68F4A9",
      },
      "& li::marker":{
        color:"#68F4A9",
      },
      
      "& .asset-wrapper":{
        marginTop:50,
        width:"90%",
        maxWidth:570,
        margin:"0 auto",
        
      },
      "& .code-wrapper":{
        marginTop:50,
        width:"95%",
        maxWidth:7000,
        margin:"0 auto",
        fontSize: "0.9em",
        lineHeight: "1.15rem",
        fontFamily:"monospace,monospace",
        borderRadius:50,
      
        '@media (max-width:875px)': {
          fontSize:14,
          lineHeight:"1rem"
       }
      
        
      }
    },
   
    

})


function ArticlePage({data}) {

    const classes = useStyles();

    const article = data.contentfulBlog;
    const time = readingTime(article.blogBody.raw).text;
    
    return (
        <div>
            <Helmet>
                <title>{article.title}</title>
                <link rel="canonical" href={`https://www.momar.dev/blog/${article.slug}`} />
            </Helmet>
            <Layout>
                <div className={classes.article}>
                    <h1 className={classes.title}> {article.title}</h1>
                    <p className={classes.readingTime}>{`${time}.`}</p>
                    <div className={classes.socialsShare}>
                      <LinkedinShareButton url={`https://www.momar.dev/blog/${article.slug}`}>
                        <LinkedinIcon size={40} round={true}/>
                      </LinkedinShareButton>
                      <RedditShareButton url={`https://www.momar.dev/blog/${article.slug}`}>
                        <RedditIcon size={40} round={true}/>
                      </RedditShareButton>
                      <TwitterShareButton url={`https://www.momar.dev/blog/${article.slug}`}>
                        <TwitterIcon size={40} round={true}/>
                      </TwitterShareButton>
                    </div>
                    <h4 className={classes.date}>{article.updatedAt}</h4>
                    <GatsbyImage className={classes.cover} image={article.cover.gatsbyImageData} alt="image-blog"/>
                    <div className={classes.content}>
                    {article.blogBody && renderRichText(article.blogBody, options)}
                    </div>
                </div>
            </Layout>
            
        </div>
    );
}


export const query = graphql`
  query articlePage($slug: String){
    contentfulBlog(slug: {eq: $slug}) {
      blogBody {
        raw
        references{
          __typename
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
          ... on ContentfulCodesnippet {
            contentful_id
            language
            code {
              code
            }
          }
          
        }
      }
      cover {
        gatsbyImageData
      }
      updatedAt(formatString: "MMM DD, YYYY")
      id
      slug
      title
    }
  }
`

export default ArticlePage;